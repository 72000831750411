import React from 'react'
import 'twin.macro'

export default function InfoDetail({data,page}){
  let finalData= data?.schema_json;  
  let content= {
      heading:"A better way to send money",
      sub_heading:"Transactions",
      left_text:"Ultrices ultricies a in odio consequat egestas rutrum. Ut vitae aliquam in ipsum. Duis nullam placerat cursus risus ultrices nisi, vitae tellus in. Qui non fugiat aut minus aut rerum. Perspiciatis iusto mollitia iste minima soluta id.<br />Erat pellentesque dictumst ligula porttitor risus eget et eget. Ultricies tellus felis id dignissim eget. Est augue maecenas risus nulla ultrices congue nunc tortor. Eu leo risus porta integer suspendisse sed sit ligula elit.<br />Integer varius imperdiet sed interdum felis cras in nec nunc.<br />Quam malesuada odio ut sit egestas. Elementum at porta vitae.<br />Amet, eu nulla id molestie quis tortor. Auctor erat justo, sed pellentesque scelerisque interdum blandit lectus. Nec viverra amet ac facilisis vestibulum. Vestibulum purus nibh ac ultricies congue.<br />",
      right_text:"Erat pellentesque dictumst ligula porttitor risus eget et eget. Ultricies tellus felis id dignissim eget. Est augue maecenas risus nulla ultrices congue nunc tortor.<br />Eu leo risus porta integer suspendisse sed sit ligula elit. Elit egestas lacinia sagittis pellentesque neque dignissim vulputate sodales. Diam sed mauris felis risus, ultricies mauris netus tincidunt. Mauris sit eu ac tellus nibh non eget sed accumsan. Viverra ac sed venenatis pulvinar elit. Cras diam quis tincidunt lectus. Non mi vitae, scelerisque felis nisi, netus amet nisl.<br />Eu eu mauris bibendum scelerisque adipiscing et. Justo, elementum consectetur morbi eros, posuere ipsum tortor. Eget cursus massa sed velit feugiat sed ut. Faucibus eros mauris morbi aliquam nullam. Scelerisque elementum sit magna ullamcorper dignissim pretium.<br />",
     ...finalData
} 
    return (
      <div tw="px-4 py-4 md:py-8 mx-auto w-full md:px-24 lg:px-8">
        <div tw="mb-10 md:mx-auto text-center md:mb-12 md:w-11/12 ">
          <p tw="text-base text-gray-700 md:text-lg">
          {content.sub_heading}
          </p>
          <h3 tw="mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto text-primary-900">
          {content.heading}
          </h3>
        </div>
        <div tw="relative w-full p-px mx-auto mb-0 overflow-hidden transition-shadow duration-300 border rounded  hover:shadow-xl md:w-11/12 bg-gradient-to-r from-primary-100 to-secondary-200">

          <div tw="relative flex flex-col items-center h-full py-10 duration-300 rounded-sm md:items-stretch md:flex-row">
            <div tw="px-4 md:px-8 py-4 md:py-8 text-center md:w-1/2">
              <div tw="text-center md:text-base"  dangerouslySetInnerHTML={{__html:content.left_text}} />
            </div>
            <div tw="w-56 h-1 transition duration-300 transform bg-gray-300 rounded-full group-hover:bg-primary-400 group-hover:scale-110 md:h-auto md:w-1" />
            <div tw="px-4 md:px-8 py-4 md:py-8 text-center md:w-1/2">
              <div tw="text-center md:text-base"  dangerouslySetInnerHTML={{__html:content.right_text}} />
            </div>
          </div>
        </div>
      </div>
    );
  };